<template>
  <div>
    <v-toolbar flat class="mb-2">
      <SchoolYearPicker outlined text v-model="schoolYear" />
      <v-btn-toggle
        v-model="filter"
        mandatory
        dense
        class="ml-4"
        color="primary"
      >
        <v-btn text :value="0">alle</v-btn>
        <v-btn text :value="1">erstellt</v-btn>
        <v-btn text :value="2">nicht erstellt</v-btn>
      </v-btn-toggle>

      <v-spacer />
      <v-text-field
        single-line
        dense
        hide-details
        label="Suche"
        v-model="search"
        append-icon="mdi-magnify"
        clearable
      ></v-text-field>
    </v-toolbar>
    <v-alert text border="left" type="info" prominent
      >{{ items.filter((el) => el.portfolio).length }} Portfolios sind bereits
      erstellt, {{ items.filter((el) => !el.portfolio).length }} noch
      nicht.</v-alert
    >
    <v-card>
      <v-system-bar>Portfolios</v-system-bar>
      <v-data-table
        :loading="loading"
        :items="itemsFiltered"
        :headers="headers"
        disable-filtering
        disable-pagination
        hide-default-footer
        must-sort
        sort-by="placement.student"
        @click:row="(el) => showDetail(el)"
        :item-class="(el) => (el.portfolio ? 'clickable' : '')"
      >
        <template v-slot:item.portfolio="{ item }">
          <v-icon>{{ item.portfolio ? "mdi-check" : "" }}</v-icon>
        </template>
        <template v-slot:item.placement.student="{ item }">
          <PersonItem
            v-if="item.placement.student"
            :value="item.placement.student"
            dense
          >
          </PersonItem>
        </template>
        <template v-slot:item.placement.institution="{ item }">
          <InstitutionItem
            :value="item.placement.institution"
            dense
          ></InstitutionItem>
        </template>
        <template v-slot:item.placement.startDate="{ item }">
          <DateValue :value="item.placement.startDate"></DateValue>
        </template>
        <template v-slot:item.placement.endDate="{ item }">
          <DateValue :value="item.placement.endDate"></DateValue>
        </template>

        <template v-slot:item.portfolio.creationDate="{ item }">
          <DateValue
            v-if="item.portfolio"
            :value="item.portfolio.creationDate"
          ></DateValue>
          <span v-else>–</span>
        </template>
      </v-data-table>
    </v-card>
    <router-view></router-view>
  </div>
</template>

<script>
import DateValue from "common/components/DateValue.vue";
import InstitutionItem from "./InstitutionItem.vue";
import PersonItem from "@/components/PersonItem.vue";
import SchoolYearPicker from "@/components/SchoolYearPicker.vue";

import { comparePeople, searchPerson } from "common/utils/people.js";

export default {
  components: {
    DateValue,
    InstitutionItem,
    PersonItem,
    SchoolYearPicker,
  },
  data() {
    return {
      loading: false,
      filter: 0,
      student: null,
      emptyPlacements: [],
      saving: false,
      headers: [
        {
          text: "",
          value: "portfolio",
          sort: comparePeople,
        },
        {
          text: "Schüler*in",
          value: "placement.student",
          sort: comparePeople,
        },
        {
          text: "von",
          value: "placement.startDate",
        },
        {
          text: "bis",
          value: "placement.endDate",
        },
        {
          text: "Institution",
          value: "placement.institution",
          sort: this.compareInstitutions,
        },

        {
          text: "erzeugt am",
          value: "portfolio.creationDate",
        },
      ],
      items: [],
      item: {},
      schoolYear: null,
      search: "",
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (el) =>
          el.placement.student &&
          el.placement.institution &&
          (this.filter == 0 ||
            (this.filter == 1 && el.portfolio) ||
            (this.filter == 2 && !el.portfolio)) &&
          (!this.search ||
            searchPerson(el.placement.student, this.search) ||
            this.institutionSearch(el.placement, this.search))
      );
    },
  },
  watch: {
    schoolYear() {
      this.fetchData();
    },
  },
  methods: {
    institutionSearch(el, search) {
      return (
        (el.institution &&
          el.institution.instName &&
          el.institution.instName
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (el.institution &&
          el.institution.firstName1 &&
          el.institution.firstName1
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (el.institution &&
          el.institution.lastName1 &&
          el.institution.lastName1
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (el.institution &&
          el.institution.firstName2 &&
          el.institution.firstName2
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (el.institution &&
          el.institution.lastName2 &&
          el.institution.lastName2
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (el.institution &&
          el.institution.town &&
          el.institution.town.toLowerCase().includes(search.toLowerCase()))
      );
    },

    compareInstitutions(a, b) {
      const strA = `${a.instName ? a.instName : ""}${
        a.lastName1 ? a.lastName1 : ""
      }${a.lastName2 ? a.lastName2 : ""}`;
      const strB = `${b.instName ? b.instName : ""}${
        b.lastName1 ? b.lastName1 : ""
      }${b.lastName2 ? b.lastName2 : ""}`;
      return strA.localeCompare(strB, "de-CH");
    },

    async fetchData() {
      this.loading = true;

      this.items = await this.apiList({
        resource: "portfolio/placement",
        query: "schoolYear=" + this.schoolYear.id,
      });

      this.loading = false;
    },
    showDetail(el) {
      if (el.portfolio) {
        this.$router.push({
          name: "PlacementPortfolioDetail",
          params: { id: el.portfolio.id },
        });
      }
    },
  },
  created() {},
};
</script>
